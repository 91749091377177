<template>
  <div class="container wizard">
    <div class="row">
      <div class="col-12 p-0">
        <h2>Listado de usuarios</h2>
      </div>
    </div>

    <div class="col-12">
      <b-form-group
        label="Filtro"
        label-for="filter-input"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        content-cols=""
        class="mb-0"
      >
        <b-input-group>
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Tipee nombre del usuario"
            @keydown="currentPage = 1"
          ></b-form-input>

          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="
                filter = '';
                currentPage = 1;
              "
              >Buscar</b-button
            >
          </b-input-group-append>
          <b-button variant="outline-primary" @click="add">Agregar</b-button>
        </b-input-group>
      </b-form-group>
    </div>

    <div class="row content">
      <div class="col-12">
        <b-table
          striped
          hover
          :items="items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filter"
        >
          <template v-slot:cell(actions)="{ item }">
            <span><b-btn @click="edit(item)">Editar</b-btn></span>
            <span><b-btn @click="del(item)">Elimniar</b-btn></span>
          </template></b-table
        >
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import userService from "../../services/user.service";

export default {
  data() {
    return {
      filter: "",
      totalRows: 0,
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "country",
          label: "Pais",
          sortable: false,
        },
        {
          key: "userName",
          label: "Nombre",
          sortable: false,
          formatter: (value) => {
            return value ? value.substring(0, 16).replace("T", " ") : "";
          },
        },
        {
          key: "email",
          label: "Email",
          sortable: false,
        },
        {
          key: "state",
          label: "Estado",
          sortable: false,
        },
        { key: "actions", label: "Actions" },
      ],
      items: [],
    };
  },
  mounted: function () {
    this.getAll();
  },
  onFiltered(items) {
    this.totalRows = items ? items.length : 0;
    this.currentPage = 1;
  },
  methods: {
    getAll() {
      this.$store.dispatch("app/loading", true);
      userService
        .getAll({ perPage: 10, currentPage: 1 })
        .then((data) => {
          this.items = data;
          this.totalRows = data.length;
        })
        .finally(() => {
          this.$store.dispatch("app/loading", false);
        });
    },
    add() {
      this.$store.dispatch("app/loading", true);
      this.$store
        .dispatch("users/save", {
          userName: "",
          email: "",
          id: undefined,
          country: "",
        })
        .then(this.$router.push("/user-edit.html"))
        .finally(() => {
          this.$store.dispatch("app/loading", false);
        });
    },

    edit(item) {
      this.$store.dispatch("app/loading", true);
      this.$store
        .dispatch("users/save", {
          userName: item.userName,
          email: item.email,
          id: item.id,
          country: item.country,
        })
        .then(this.$router.push("/user-edit.html"))
        .finally(() => {
          this.$store.dispatch("app/loading", false);
        });
    },

    del(item) {
      this.$store.dispatch("app/loading", true);
      userService
        .delete(item)
        .then(() => {
          this.getAll();
        })
        .finally(() => {
          this.$store.dispatch("app/loading", false);
        });
    },
  },

  computed: {
    rows() {
      return this.items.length;
    },
  },
};
</script>

<style scoped></style>
